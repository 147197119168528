import { Accordion, AccordionDetails, AccordionSummary, Avatar, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSingleTransaction } from '../../../../services';
import { Loading } from '../../../assets/Loading';
import { HeaderTable } from '../../../components/core/HeaderTable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactJson from 'react-json-view';
import moment from 'moment';
import { statusTransactionsIcon, typeTransaction } from '../../../commons/hooks';

export const TransactionsDetails = ({ idx }: any) => {
  const { id } = useParams();
  const { callCustomers: callTransaction, data } = useGetSingleTransaction({ transactionId: '' }, { autoCall: false });
  const [transaction, setTransaction] = useState<null | any>(null);

  useEffect(() => {
    data && setTransaction(data.transaction);
  }, [data, setTransaction]);

  useEffect(() => {
    id && callTransaction({ transactionId: id });
  }, [id, callTransaction]);

  return !transaction ? (
    <Grid container height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Loading viewBox="0 0 100 100" width="150px" height="150px" stroke="#FAC306" />
    </Grid>
  ) : (
    <Grid container direction="column" p={5}>
      <Grid item>
        <HeaderTable title={`Transacción: ${data?.transaction.transactionId}`} />
      </Grid>
      <Grid item mb={2}>
        <Accordion expanded={true}>
          <AccordionSummary aria-controls={`panel${idx}a-content`} id={`panel${idx}a-header`}>
            <Typography style={{ fontWeight: 'bold', color: 'black' }}>Datos de transacción</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Tipo de transacción:</Typography>
                <Typography>{typeTransaction(transaction)}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Compañía:</Typography>
                <Grid item display={'flex'} gap={1} alignItems={'center'}>
                  <Typography>{data?.transaction.company.name}</Typography>
                  <Avatar src={data?.transaction.company.logo} variant="square" sx={{ width: 24, height: 24 }} />
                </Grid>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Estado:</Typography>
                <Typography height={24}>{statusTransactionsIcon(transaction)}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Fecha:</Typography>
                <Typography>{moment(data?.transaction.updatedAt).format('D-MM-YYYY')}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item mb={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${idx}a-content`} id={`panel${idx}a-header`}>
            <Typography style={{ fontWeight: 'bold', color: 'black' }}>Datos de cliente</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Email:</Typography>
                <Typography>{data?.transaction.customer.email}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>CIS Cliente ID:</Typography>
                <Typography>{data?.transaction.customer.cisId}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Cliente ID:</Typography>
                <Typography>{data?.transaction.customer.customerId}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item>
        <div>
          {Object.entries(transaction.metadata).map((meta, idx) => {
            return (
              <Accordion sx={{ marginBottom: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${idx}a-content`} id={`panel${idx}a-header`}>
                  <Typography style={{ fontWeight: 'bold', color: 'black' }}>{String(meta[0]).toUpperCase().split('_').join(' ')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid>
                    <ReactJson src={{ ...meta }} collapsed={true} />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </Grid>
    </Grid>
  );
};
