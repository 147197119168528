import { Grid, Box, ThemeProvider } from '@mui/material';
import { orderBy as _orderBy } from 'lodash';
import moment from 'moment';
import { useMemo, useState, useCallback, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { BoUser, BoUsers, useGetAccounts } from '../../../../services';
import { useDebounce, useOrderBy } from '../../../commons/hooks';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { useSnackbar } from 'notistack';
import { ModalBaseCIS, ModalBaseProps } from '../../../components/dialogs';
import { FormData, StatusUsersCIS } from './components';
import { usePostAccount } from '../../../../services/usePostAccount';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { buttonStyles } from '../../../../theme/styles';
import { themeDataGrid, themeSearch } from '../../../../theme/colors';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

type ErrorObj = { error: any };
type UsersoBJ = BoUsers;

const isError = (errorOrResponse: ErrorObj | UsersoBJ): errorOrResponse is ErrorObj => {
  return (errorOrResponse as ErrorObj).error !== undefined;
};

function CustomToolbar() {
  const classes = buttonStyles();
  return (
    <GridToolbarContainer>
      <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={0.5} pl={1} pr={1} mb={4}>
        <GridToolbarExport className={classes.button} />
        <ThemeProvider theme={themeSearch}>
          <GridToolbarQuickFilter />
        </ThemeProvider>
      </Grid>
    </GridToolbarContainer>
  );
}

export const Users = () => {
  const { direction, orderBy, onOrderByDirectionChange } = useOrderBy('created_at', 'desc');
  const [value, setValue] = useState('');
  const [dataUser, setDataUser] = useState<BoUser[]>([]);
  const [endReached, setEndReached] = useState(false);
  const [limit] = useState(40);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const searchValue = useDebounce(value, 1000);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState(dataUser);

  const [modal, setModal] = useState<ModalBaseProps>({
    open: false,
    title: ``,
    content: null,
  });

  const filter = searchValue;
  const paramsValue = useMemo(
    () => ({
      orderBy,
      direction,
      limit,
      offset: 0,
      name: `customers`,
    }),
    [orderBy, direction, limit],
  );

  const { data, working, callCustomers: callUsers } = useGetAccounts({}, { autoCall: false });
  const {
    data: responseAccountStatus,
    working: workingAccountStatus,
    error: errorAccountStatus,
    callCustomers: updateAccountStatus,
  } = usePostAccount(
    {
      note: ``,
      status: false,
      userId: ``,
    },
    { autoCall: false },
  );

  const fetching = useCallback(async () => {
    try {
      setLoading(true);
      const params = filter === '' ? paramsValue : { ...paramsValue, filter };
      const res = await callUsers(params);
      if (res && !isError(res)) {
        setDataUser(res?.bo_users);
        setLoading(false);
      }
    } catch (e) {
      enqueueSnackbar(`Error al obtener datos...`, {
        variant: 'error',
      });
    }
  }, [filter, paramsValue, callUsers, enqueueSnackbar]);

  useEffect(() => {
    fetching();
  }, [paramsValue, filter, fetching]);

  const statusContent = useCallback(
    (user: BoUser) => {
      return (
        <StatusUsersCIS
          user={user}
          handleSubmit={(formData: FormData) => {
            if (formData) {
              updateAccountStatus(formData);
            }
          }}
          handleCancel={() => {
            setModal({
              ...modal,
              open: false,
            });
          }}
        ></StatusUsersCIS>
      );
    },
    [modal, updateAccountStatus],
  );

  const users = useMemo(
    () =>
      dataUser?.map((item) => ({
        ...item,
        created_at: moment(item.created_at).format('D-MM-YYYY, HH:mm:ss'),
      })),
    [dataUser],
  );

  const handleDatailsClick = (id: GridRowId) => () => {
    setRows(dataUser.filter((row) => row.bo_user_id !== id));
    navigate(`/users/${id}`);
  };

  const handleStateClick = () => {
    setRows(
      dataUser.filter((row) =>
        setModal({
          ...modal,
          title: `Estado`,
          open: !modal.open,
          content: statusContent(row),
        }),
      ),
    );
  };

  const columnsUser: GridColDef[] = [
    {
      field: 'bo_user_id',
      headerName: 'ID',
      editable: false,
      flex: 1,
    },
    {
      field: 'first_name',
      headerName: 'Nombre',
      flex: 1,
      editable: false,
    },
    {
      field: 'last_name',
      headerName: 'Apellido',
      flex: 1,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: false,
    },
    {
      field: 'created_at',
      headerName: 'Fecha de creación',
      flex: 0.5,
      editable: false,
    },
    {
      field: 'operations',
      headerName: 'Operaciones',
      type: 'actions',
      flex: 0.5,
      editable: false,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem icon={<VisibilityOutlinedIcon />} label="Details" className="textPrimary" onClick={handleDatailsClick(id)} color="inherit" />,
          <GridActionsCellItem icon={<AssignmentTurnedInIcon />} label="Edit" className="textPrimary" onClick={handleStateClick} color="inherit" />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (responseAccountStatus?.status) {
      setModal({
        ...modal,
        open: false,
      });
    }
  }, [modal, responseAccountStatus]);

  return (
    <Fragment>
      <Box width="100%" height="100%" paddingX={4}>
        <Grid container rowSpacing={4} spacing={4}>
          <Grid item xs={8}>
            <HeaderTable title="Administradores" />
          </Grid>
          <Grid item xs={12} pb={4} sx={{ height: 700, width: '100%', '& .actions': { color: 'text.secondary' }, '& .textPrimary': { color: 'text.primary' } }}>
            <ThemeProvider theme={themeDataGrid}>
              <DataGrid
                autoPageSize
                localeText={{ toolbarExportCSV: 'Exportar a CSV', toolbarExportPrint: 'Imprimir', toolbarExport: 'Exportar' }}
                getRowId={(id) => id?.bo_user_id}
                columns={columnsUser}
                rows={users || []}
                loading={loading}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </ThemeProvider>
            <ModalBaseCIS open={modal.open} title={modal.title} content={modal.content}></ModalBaseCIS>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
