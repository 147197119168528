import React, { useEffect, useState } from 'react';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, ThemeProvider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCustomerInfo } from '../../../../services/useGetCustomerInfo';
import { Loading } from '../../../assets/Loading';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { DataGrid, GridActionsCellItem, GridRowId, GridRowsProp, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { themeDataGrid, themeSearch } from '../../../../theme/colors';
import { buttonStyles } from '../../../../theme/styles';
import { GridColDef } from '@mui/x-data-grid';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useGetCustomerTransactionsService } from '../../../../services';
import { statusTransaction, typeTransaction } from '../../../commons/hooks';

function CustomToolbar() {
  const classes = buttonStyles();
  return (
    <GridToolbarContainer>
      <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={0.5} pl={1} pr={1} mb={4}>
        <GridToolbarExport className={classes.button} />
        <ThemeProvider theme={themeSearch}>
          <GridToolbarQuickFilter />
        </ThemeProvider>
      </Grid>
    </GridToolbarContainer>
  );
}

export const CustomerDetails = () => {
  const { id } = useParams();
  const { data, working } = useGetCustomerInfo({ customer_id: id }, { autoCall: true });
  const { data: dataCustomerTransactions, working: loading, callCustomers: callCustomerTransactions } = useGetCustomerTransactionsService({ customer_id: id || '' }, { autoCall: false });
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });

  const customerTransactionsRows = dataCustomerTransactions?.transactions.map((item) => ({
    transaction_id: item.transactionId,
    email: item.customer.email,
    company: item.company.name,
    transaction_type: typeTransaction(item),
    status: statusTransaction(item),
    created_at: moment(item.createdAt).format('D-MM-YYYY, HH:mm:ss'),
    customer_id: item.customer.customerId,
  }));

  const columnsTransactionById: GridColDef[] = [
    { field: 'transaction_id', headerName: 'ID', flex: 1, editable: false, disableColumnMenu: true, sortable: false },
    { field: 'company', headerName: 'Compañía', flex: 1, editable: false, disableColumnMenu: true },
    { field: 'transaction_type', headerName: 'Tipo de Transaccion', flex: 1, editable: false, disableColumnMenu: true },
    { field: 'status', headerName: 'Estado', flex: 1, editable: false, disableColumnMenu: true },
    { field: 'created_at', headerName: 'Creada', flex: 1, editable: false, disableColumnMenu: true },
    {
      field: 'operations',
      headerName: 'Operaciones',
      type: 'actions',
      editable: false,
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [<GridActionsCellItem icon={<VisibilityOutlinedIcon />} label="Details" className="textPrimary" onClick={handleDatailsClick(id)} color="inherit" />];
      },
    },
  ];

  const handleDatailsClick = (id: GridRowId) => () => {
    navigate(`/transactions/${id}`);
  };

  useEffect(() => {
    callCustomerTransactions({ limit: paginationModel.pageSize, offset: paginationModel.page * paginationModel.pageSize, customer_id: id || '' });
  }, [paginationModel]);

  return working ? (
    <Grid container height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <Loading viewBox="0 0 100 100" width="150px" height="150px" stroke="#FAC306" />
    </Grid>
  ) : (
    <Grid container direction="column" p={5}>
      <Grid item>
        <HeaderTable title="Detalle de cliente" />
      </Grid>
      <Grid item mb={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontWeight: 'bold', color: 'black' }}>Datos de cliente</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Cliente ID:</Typography>
                <Typography>{data?.customer?.customer_id}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Cuenta ID:</Typography>
                <Typography>{data?.customer?.account_id}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black', marginLeft: 10 }}>Creado:</Typography>
                <Typography>{moment(data?.customer?.created_at).format('D-MM-YYYY')}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Teléfono:</Typography>
                <Typography>{data?.customer?.phone}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Email:</Typography>
                <Typography>{data?.customer?.email}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Dispositivo ID:</Typography>
                <Typography>{data?.customer?.device_id}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>País:</Typography>
                <Typography>{data?.customer?.country_name}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Estado de onboarding:</Typography>
                <Typography>{String(data?.customer?.onboarding_profile_status)}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item mb={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontWeight: 'bold', color: 'black' }}>Cuenta de CIS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>CIS ID:</Typography>
                <Typography>{data?.customer?.cis_id}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Usuario ID:</Typography>
                <Typography>{data?.customer?.cis_user_id}</Typography>
              </Grid>
              <Grid item style={{ padding: 10, background: '#e1e1e1', marginLeft: 10, marginBottom: 10, width: 400, borderRadius: 10 }}>
                <Typography style={{ fontWeight: 'bold', color: 'black' }}>Perfil ID:</Typography>
                <Typography>{data?.customer?.cis_profile_id}</Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item mb={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontWeight: 'bold', color: 'black' }}>Transacciones</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row">
              <Grid item pb={4} sx={{ height: 500, width: '100%', '& .actions': { color: 'text.secondary' }, '& .textPrimary': { color: 'text.primary' } }}>
                <ThemeProvider theme={themeDataGrid}>
                  <DataGrid
                    localeText={{ toolbarExportCSV: 'Exportar a CSV', toolbarExportPrint: 'Imprimir', toolbarExport: 'Exportar' }}
                    getRowId={(id) => id?.transaction_id}
                    columns={columnsTransactionById}
                    rows={customerTransactionsRows || []}
                    filterMode="server"
                    loading={loading}
                    rowCount={+(dataCustomerTransactions?.transaction_count || 0)}
                    paginationMode="server"
                    pageSizeOptions={[10, 25, 50]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    slots={{ toolbar: CustomToolbar }}
                  />
                </ThemeProvider>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
