import { TransactionsObj } from '../application/commons/types/transactionTypes';
import { UseStubbornServiceOptions, useStubbornService } from '../lib/StubbornService/react';

const SERVICE_ID = 'getCustomerTransactions';

export interface CustomerTransactionsResponse {
  transaction_count: string;
  transactions: TransactionsObj[];
}

export const useGetCustomerTransactionsService = (params: { customer_id: string }, options?: UseStubbornServiceOptions) =>
  useStubbornService<CustomerTransactionsResponse>(SERVICE_ID, params, options);
