import { TransactionsObj } from '../types/transactionTypes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CancelIcon from '@mui/icons-material/Cancel';
import { primary } from '../../../theme/colors';

export const statusTransactionsIcon = (item: TransactionsObj) => {
  if (item.status.name == 'approved') {
    return <CheckCircleIcon sx={{ color: 'green' }} />;
  }
  if (item.status.name == 'rejected') {
    return <CancelIcon sx={{ color: 'red' }} />;
  }
  if (item.status.name == 'pending') {
    return <AccessTimeFilledIcon sx={{ color: primary.yellowCis }} />;
  }
  if (item.status.name == 'in-progress') {
    return <AccessTimeFilledIcon sx={{ color: primary.yellowCis }} />;
  }
  if (item.status.name == 'completed') {
    return <CheckCircleIcon sx={{ color: 'green' }} />;
  }
};

