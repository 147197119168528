import { useMemo, useState, useCallback, useEffect, Fragment } from 'react';
import { Grid, Box, ThemeProvider } from '@mui/material';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Customer, useGetCustomers } from '../../../../services';
import { HeaderTable } from '../../../components/core/HeaderTable';
import { buttonStyles } from '../../../../theme/styles';
import { DataGrid, GridRowId, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, GridValidRowModel } from '@mui/x-data-grid';
import { themeDataGrid, themeSearch } from '../../../../theme/colors';
import { GridColDef } from '@mui/x-data-grid';
import { GridActionsCellItem } from '@mui/x-data-grid';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { GridRowParams } from '@mui/x-data-grid';

function CustomToolbar() {
  const classes = buttonStyles();
  return (
    <GridToolbarContainer>
      <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={0.5} pl={1} pr={1} mb={4}>
        <GridToolbarExport className={classes.button} />
        <ThemeProvider theme={themeSearch}>
          <GridToolbarQuickFilter />
        </ThemeProvider>
      </Grid>
    </GridToolbarContainer>
  );
}

type UseLocationState = { email: string } | null;

export const Customers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const stateFromUsers = location.state as UseLocationState;
  const [value, setValue] = useState(stateFromUsers?.email || '');
  const { data, working: loading, callCustomers } = useGetCustomers({}, { autoCall: false });
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });

  console.log(location.state);

  const customers = useMemo(
    () =>
      data?.customers.map((item, idx) => ({
        customer_id: item?.customer_id,
        email: item?.email,
        country_name: item?.country_name,
        created_at: moment(item.created_at).format('D-MM-YYYY, HH:mm:ss'),
        cis_id: item?.cis_id,
        cis_profile_id: item?.cis_profile_id,
        cis_user_id: item?.cis_user_id,
        key: `element_${idx}`,
      })) || [],
    [data],
  );

  const columnsCustomer: GridColDef[] = [
    { field: 'customer_id', headerName: 'ID', flex: 1, editable: false },
    { field: 'email', headerName: 'Email', flex: 1, editable: false },
    { field: 'country_name', headerName: 'País', flex: 1, editable: false },
    { field: 'created_at', headerName: 'Fecha de creación', flex: 1, editable: false },
    { field: 'cis_id', headerName: 'CIS ID', flex: 1, editable: false, align: 'right' },
    { field: 'cis_profile_id', headerName: 'Usuario ID', flex: 1, editable: false, align: 'right' },
    { field: 'cis_user_id', headerName: 'Perfil ID', flex: 1, editable: false, align: 'right' },
    {
      field: 'operations',
      headerName: 'Operaciones',
      type: 'actions',
      editable: false,
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [<GridActionsCellItem icon={<VisibilityOutlinedIcon />} label="Details" className="textPrimary" onClick={handleDatailsClick(id)} color="inherit" />];
      },
    },
  ];

  const handleDatailsClick = (id: GridRowId) => () => {
    navigate(`/customers/${id}`);
  };

  useEffect(() => {
    callCustomers({ limit: paginationModel.pageSize, offset: paginationModel.page * paginationModel.pageSize, orderBy: '', direction: '' });
  }, [paginationModel]);

  return (
    <Fragment>
      <Box width="100%" height="100%" paddingX={4}>
        <Grid container rowSpacing={4} spacing={4}>
          <Grid item xs={8}>
            <HeaderTable title="Clientes" />
          </Grid>
          <Grid item pb={4} sx={{ height: 700, width: '100%', '& .actions': { color: 'text.secondary' }, '& .textPrimary': { color: 'text.primary' } }}>
            <ThemeProvider theme={themeDataGrid}>
              <DataGrid
                localeText={{ toolbarExportCSV: 'Exportar a CSV', toolbarExportPrint: 'Imprimir', toolbarExport: 'Exportar' }}
                getRowId={(id) => id?.customer_id}
                columns={columnsCustomer}
                rows={customers || []}
                slots={{ toolbar: CustomToolbar }}
                loading={loading}
                rowCount={+(data?.customers_count.total || 0)}
                pageSizeOptions={[10, 25, 50]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                initialState={{
                  ...data?.customers,
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [''],
                    },
                  },
                }}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
